import { cleanObject } from './utils.js';

export function setupTagList(model) {
    function makeParams(model) {
        const name = model.pagination != null ? model.pagination.name : null;
        return cleanObject({
            n: name,
            e: name != null ? true : undefined,
            l: model.limit,
            d: model.desc ? true : undefined
        });
    }

    let first = null;
    let last = null;
    let pageEvent = null;
    let pageIndex = 0;
    let state = { params: makeParams(model) };
    let table = null;

    $(window).on('popstate', function (e) {
        first = null;
        last = null;
        pageEvent = null;
        pageIndex = 0;

        if (e.originalEvent.state != null) {
            state = e.originalEvent.state;
        } else {
            state.params = makeParams(model);
        }

        if (table != null) {
            table.api().ajax.reload();
        }
    });

    $(document).ready(function () {
        document.addEventListener('click', function (e) {
            if ($(e.target).hasClass('page-link')) {
                pageEvent = e.target.getAttribute('data-dt-idx');
            }
        }, true);

        table = $('#tags-search-result').dataTable({
            pagingType: 'full',
            lengthChange: false,
            pageLength: model.limit ?? 40,
            scrollY: 'calc(100vh - 20.5rem)',
            scrollCollapse: true,
            serverSide: true,
            processing: true,
            columns: [
                { data: 'id', title: 'Id', readonly: true, type: 'hidden', visible: false },
                { data: 'displayName', title: 'Tag' },
                { data: 'description', title: 'Description' }
            ],
            select: {
                blurable: true
            },
            altEditor: true,
            layout: {
                topStart: {
                    buttons: [
                        { name: 'add', text: '<i class="fas fa-fw fa-plus-circle fa-sm"></i>' },
                        { name: 'edit', text: '<i class="fas fa-fw fa-edit fa-sm"></i>', extend: 'selectedSingle' },
                        { name: 'delete', text: '<i class="fas fa-fw fa-trash fa-sm"></i>', extend: 'selected' },
                        { name: 'refresh', text: '<i class="fas fa-fw fa-sync-alt fa-sm"></i>' }
                    ]
                }
            },
            ajax: {
                url: `/api/tags`,
                type: 'GET',
                cache: true,
                data: () => {
                    $('.dt-paging-button').addClass('disabled');

                    let push = false;

                    switch (pageEvent) {
                        case 'first':
                            push = true;
                            state.params.n = undefined;
                            state.params.e = undefined;
                            state.params.d = undefined;
                            break;
                        case 'previous':
                            push = true;
                            state.params.n = first;
                            state.params.e = first != null ? true : undefined;
                            state.params.d = true;
                            break;
                        case 'next':
                            push = true;
                            state.params.n = last;
                            state.params.e = last != null ? true : undefined;
                            state.params.d = undefined;
                            break;
                        case 'last':
                            push = true;
                            state.params.n = undefined;
                            state.params.e = undefined;
                            state.params.d = true;
                            break;
                    }

                    if (push) {
                        state.params = cleanObject(state.params);

                        const url = new URL(window.location);
                        url.search = new URLSearchParams(state.params).toString();
                        history.pushState(state, '', url);
                    }
                    pageEvent = false;

                    return state.params;
                },
                dataFilter: response => {
                    const json = JSON.parse(response);

                    const tags = json.tags;
                    const prev = json.prev;
                    const next = json.next;

                    if (tags.length > 0) {
                        first = tags[0].value;
                        last = tags[tags.length - 1].value;
                    }

                    const count = (!prev && !next) ? (model.limit) : (model.limit * 5);
                    pageIndex = prev ? (next ? 2 : 4) : 0;

                    return JSON.stringify({
                        recordsTotal: count,
                        recordsFiltered: count,
                        data: tags
                    });
                }
            },
            preDrawCallback: settings => (new $.fn.dataTable.Api(settings)).page(pageIndex),
            onAddRow: (datatable, rowdata, success, error) => {
                const { id, ...withoutId } = rowdata;
                $.ajax({
                    url: `/api/tags`,
                    type: 'POST',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify(withoutId),
                    success: success,
                    error: error
                });
            },
            onEditRow: (datatable, rowdata, success, error) => {
                const { id, ...withoutId } = rowdata;
                $.ajax({
                    url: `/api/tags/${id}`,
                    type: 'PUT',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify(withoutId),
                    success: success,
                    error: error
                });
            },
            onDeleteRow: (datatable, rowdatas, success, error) => {
                const ids = rowdatas.map(rowdata => rowdata.id);
                $.ajax({
                    url: `/api/tags`,
                    type: 'DELETE',
                    contentType: 'application/json',
                    dataType: 'json',
                    data: JSON.stringify({ tagIds: ids }),
                    success: success,
                    error: error
                });
            }
        });
    });
}