// Generated from QueryParser.g4 by ANTLR 4.13.2
// jshint ignore: start
import antlr4 from 'antlr4';

// This class defines a complete listener for a parse tree produced by QueryParser.
export default class QueryParserListener extends antlr4.tree.ParseTreeListener {

	// Enter a parse tree produced by QueryParser#query.
	enterQuery(ctx) {
	}

	// Exit a parse tree produced by QueryParser#query.
	exitQuery(ctx) {
	}


	// Enter a parse tree produced by QueryParser#BracketExpression.
	enterBracketExpression(ctx) {
	}

	// Exit a parse tree produced by QueryParser#BracketExpression.
	exitBracketExpression(ctx) {
	}


	// Enter a parse tree produced by QueryParser#AndExpression.
	enterAndExpression(ctx) {
	}

	// Exit a parse tree produced by QueryParser#AndExpression.
	exitAndExpression(ctx) {
	}


	// Enter a parse tree produced by QueryParser#TermExpression.
	enterTermExpression(ctx) {
	}

	// Exit a parse tree produced by QueryParser#TermExpression.
	exitTermExpression(ctx) {
	}


	// Enter a parse tree produced by QueryParser#NotExpression.
	enterNotExpression(ctx) {
	}

	// Exit a parse tree produced by QueryParser#NotExpression.
	exitNotExpression(ctx) {
	}


	// Enter a parse tree produced by QueryParser#OrExpression.
	enterOrExpression(ctx) {
	}

	// Exit a parse tree produced by QueryParser#OrExpression.
	exitOrExpression(ctx) {
	}


	// Enter a parse tree produced by QueryParser#term.
	enterTerm(ctx) {
	}

	// Exit a parse tree produced by QueryParser#term.
	exitTerm(ctx) {
	}


	// Enter a parse tree produced by QueryParser#tagIdTerm.
	enterTagIdTerm(ctx) {
	}

	// Exit a parse tree produced by QueryParser#tagIdTerm.
	exitTagIdTerm(ctx) {
	}


	// Enter a parse tree produced by QueryParser#tagIdValue.
	enterTagIdValue(ctx) {
	}

	// Exit a parse tree produced by QueryParser#tagIdValue.
	exitTagIdValue(ctx) {
	}


	// Enter a parse tree produced by QueryParser#tagNameTerm.
	enterTagNameTerm(ctx) {
	}

	// Exit a parse tree produced by QueryParser#tagNameTerm.
	exitTagNameTerm(ctx) {
	}


	// Enter a parse tree produced by QueryParser#tagNameValue.
	enterTagNameValue(ctx) {
	}

	// Exit a parse tree produced by QueryParser#tagNameValue.
	exitTagNameValue(ctx) {
	}


	// Enter a parse tree produced by QueryParser#wordPatternTerm.
	enterWordPatternTerm(ctx) {
	}

	// Exit a parse tree produced by QueryParser#wordPatternTerm.
	exitWordPatternTerm(ctx) {
	}


	// Enter a parse tree produced by QueryParser#wordPatternValue.
	enterWordPatternValue(ctx) {
	}

	// Exit a parse tree produced by QueryParser#wordPatternValue.
	exitWordPatternValue(ctx) {
	}


	// Enter a parse tree produced by QueryParser#wordLengthTerm.
	enterWordLengthTerm(ctx) {
	}

	// Exit a parse tree produced by QueryParser#wordLengthTerm.
	exitWordLengthTerm(ctx) {
	}


	// Enter a parse tree produced by QueryParser#wordLengthRange.
	enterWordLengthRange(ctx) {
	}

	// Exit a parse tree produced by QueryParser#wordLengthRange.
	exitWordLengthRange(ctx) {
	}



}