export function editionEnabled() {
    return sessionStorage.editionEnabled === 'true';
}

export function setupEditionEnabled() {
    const checkbox = document.getElementById('lx-edition-enabled');
    checkbox.checked = sessionStorage.editionEnabled === 'true';
    checkbox.addEventListener("change", () => {
        sessionStorage.setItem('editionEnabled', checkbox.checked);
        if (window.remakeDatatable != null && typeof window.remakeDatatable === 'function') {
            window.remakeDatatable();
        }
    });
}