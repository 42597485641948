import antlr4 from 'antlr4';
import QueryLexer from "./ql/QueryLexer";
import QueryParser from "./ql/QueryParser";

export function validateQuery(query) {
    let result = true;

    const chars = new antlr4.InputStream(query);
    const lexer = new QueryLexer(chars);
    const tokens = new antlr4.CommonTokenStream(lexer);
    const parser = new QueryParser(tokens);
    parser.buildParseTrees = true;
    lexer.removeErrorListeners();
    parser.removeErrorListeners();
    const l = { syntaxError: () => result = false };
    lexer.addErrorListener(l);
    parser.addErrorListener(l);
    parser.query();

    return result;
}