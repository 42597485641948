import { cleanObject, arraysEqual } from './utils.js';
import { editionEnabled } from './setupEdition.js';
import { validateQuery } from "./queryEditor";

const pageLengths = [25, 50, 100, 200];

function fixPageLength(pageLength) {
    if ((typeof pageLength == 'undefined') || (pageLength == null))
        return pageLengths[0];

    for (let i = pageLengths.length - 1; i > 0; --i)
        if (pageLength >= pageLengths[i])
            return pageLengths[i];
    return pageLengths[0];
}

export function setupWordList(languageId, model) {
    function makeParams(model) {
        const word = model.pagination != null ? model.pagination.word : null;
        return cleanObject({
            q: model.query,
            w: word,
            e: word != null ? true : undefined,
            l: fixPageLength(model.limit),
            d: model.desc ? true : undefined,
            c: true
        });
    }

    function extractTags(data) {
        cachedTags = data.tags.map(tag => ({
            id: tag.id,
            name: tag.displayName
        }));
        return cachedTags;
    }

    function makeTagsReq(tagIds) {
        if (tagIds == null || tagIds.length === 0) {
            return $.Deferred().resolve([]);
        }

        return $.ajax({
            url: `/api/tags`,
            type: 'GET',
            cache: true,
            data: { t: tagIds },
            dataType: 'json'
        }).then(extractTags);
    }

    function makeTagColumnHeader(tag) {
        if (editionEnabled()) {
            return `
<div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle lx-dropdown-tag" type="button"
          id="dropdownMenuButton${tag.id}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    ${tag.name}
  </button>
  <div class="dropdown-menu shadow-sm" aria-labelledby="dropdownMenuButton${tag.id}">
    <a class="dropdown-item" onclick="wordTagTagPage(${tag.id}, true)"><i class="far fa-check-square"></i>&nbsp;&nbsp;Tag&nbsp;page</a>
    <a class="dropdown-item" onclick="wordTagTagPage(${tag.id}, false)"><i class="far fa-square"></i>&nbsp;&nbsp;Untag&nbsp;page</a>
    <a class="dropdown-item" onclick="wordTagTagAll(${tag.id}, true)"><i class="fas fa-check-square"></i>&nbsp;&nbsp;Tag&nbsp;all</a>
    <a class="dropdown-item" onclick="wordTagTagAll(${tag.id}, false)"><i class="fas fa-square"></i>&nbsp;&nbsp;Untag&nbsp;all</a>
  </div>
</div>`;
        } else {
            return `
<div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle lx-dropdown-tag disabled" type="button">
    ${tag.name}
  </button>
</div>`;
        }
    }

    window.wordTagTagPage = function (tagId, enable) {
        const rowIds = table.api().rows().data().map(row => row.id).toArray();
        const inputs = rowIds.map(rowId => $(`input#check-${rowId}-${tagId}`));
        inputs.forEach(input => input.prop('disabled', true));

        $.ajax({
            url: '/api/wordtags',
            type: enable ? 'PUT' : 'DELETE',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ wordIds: rowIds, tagIds: [tagId] }),
            success: () => inputs.forEach(input => {
                input.prop('checked', enable);
                input.prop('disabled', false);
            })
        });
    };

    window.wordTagTagAll = function (tagId, enable) {
        const rowIds = table.api().rows().data().map(row => row.id).toArray();
        const inputs = rowIds.map(rowId => $(`input#check-${rowId}-${tagId}`));
        inputs.forEach(input => input.prop('disabled', true));
        table.api().processing(true);

        $.ajax({
            url: (typeof state.params.q == 'undefined' || state.params.q == null)
                ? `/api/wordtags/${languageId}`
                : `/api/wordtags/${languageId}?${$.param({ q: state.params.q })}`,
            type: enable ? 'PUT' : 'DELETE',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ tagIds: [tagId] }),
            success: () => {
                inputs.forEach(input => {
                    input.prop('checked', enable);
                    input.prop('disabled', false);
                });
                table.api().processing(false);
            }
        });
    };

    function makeTagColumn(tag) {
        if (editionEnabled()) {
            return {
                data: `tags${tag.id}`,
                title: tag.name,
                className: 'text-center',
                render: (data, type, row) =>
                    '<label class="lx-checkbox-container">'
                    + `<input type="checkbox" id="check-${row.id}-${tag.id}"${data ? ' checked' : ''} onInput="wordTagCheckbox(${row.id}, ${tag.id})">`
                    + '<span class="lx-checkbox"></span>'
                    + '</label>'
            };
        }

        return {
            data: `tags${tag.id}`,
            title: tag.name,
            className: 'text-center',
            render: (data, type, row) =>
                '<label class="lx-checkbox-container" style="pointer-events: none !important;">'
                + `<input type="checkbox" id="check-${row.id}-${tag.id}"${data ? ' checked' : ''} style="pointer-events: none !important;">`
                + '<span class="lx-checkbox" style="pointer-events: none !important;"></span>'
                + '</label>'
        };
    }

    window.wordTagCheckbox = function (rowId, tagId) {
        const input = $(`input#check-${rowId}-${tagId}`);
        const checked = input.prop('checked');

        input.prop('disabled', true);
        $.ajax({
            url: '/api/wordtags',
            type: checked ? 'PUT' : 'DELETE',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify({ wordIds: [rowId], tagIds: [tagId] }),
            success: () => input.prop('disabled', false)
        });
    };

    function convertWordResponse(words, tags) {
        return words.map(word => {
            const tagMap = Object.fromEntries(tags.map(tag => {
                return [`tags${tag.id}`, word.tagIds.includes(tag.id)];
            }));

            return {
                id: word.id,
                value: word.value,
                ...tagMap
            };
        });
    }

    function urlFromState(state) {
        const url = new URL(window.location);
        let { c, ...data } = state.params;
        data.t = state.tagColumns;
        const params = new URLSearchParams();
        Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach(value => params.append(key + '[]', value.toString()))
            } else {
                params.append(key, value.toString())
            }
        });
        url.search = params.toString();
        return url;
    }

    function initSelectTagsModal() {
        $('#select-tags-modal').on('show.bs.modal', function () {
            $('#listbox-available-tags').on('dblclick', 'option', function () {
                $('#listbox-displayed-tags').append($(this));
            });
            $('#listbox-displayed-tags').on('dblclick', 'option', function () {
                $('#listbox-available-tags').append($(this));
            });

            $('#btn-apply-tags').on('click', function () {
                const tagIds = $('#listbox-displayed-tags option').map(function () {
                    return parseInt($(this).val());
                }).toArray();
                $('#select-tags-modal').modal('hide');

                const prevTagColumns = state.tagColumns;
                state.tagColumns = tagIds;

                if (table == null || arraysEqual(prevTagColumns, tagIds)) return;

                const shrink = prevTagColumns.length > tagIds.length;
                const selector = $('#words-search-result');
                const pageLength = state.params.l;
                const tagsReq = makeTagsReq(state.tagColumns);
                tagsReq.done(tags => {
                    table.api().destroy();
                    if (shrink) $('#words-search-result').empty();
                    table = makeDatatable(selector, pageLength, tags);
                    pageEvent = 'columns';
                    table.api().ajax.reload();
                });
            });
        });
    }

    function showSelectTagsModal() {
        $.ajax({
            url: `/api/tags`,
            type: 'GET',
            cache: true,
            dataType: 'json'
        }).then(extractTags).done(tags => {
            const listboxAvailable = $('#listbox-available-tags');
            const listboxDisplayed = $('#listbox-displayed-tags');

            listboxAvailable.empty();
            listboxDisplayed.empty();

            tags.forEach(tag => {
                const option = $(`<option value="${tag.id}">${tag.name}</option>`);
                const listbox = state.tagColumns.includes(tag.id) ? listboxDisplayed : listboxAvailable;
                listbox.append(option);
            });

            $('#select-tags-modal').modal('show');
        });
    }

    let first = null;
    let last = null;
    let pageEvent = null;
    let pageIndex = 0;
    let state = { params: makeParams(model), tagColumns: model.tagIds };
    let table = null;
    let cachedTags = null;

    function makeFilterControl() {
        const value =
            (typeof state.params.q == 'undefined' || state.params.q == null)
                ? '' : state.params.q;
        return `
<input type="search" class="form-control form-control-sm"
       id="words-search-filter" placeholder="" value="${value}">
<div class="input-group-append">
  <button class="btn btn-primary btn-sm" type="button" id="words-search-filter-button">
    <i class="fas fa-search fa-sm"></i>
  </button>
</div>`;
    }

    function makePageLengthControl() {
        return `<select name="words-search-result_length"
        aria-controls="words-search-result"
        class="custom-select custom-select-sm form-control form-control-sm"
        id="dt-length-0">`
            + pageLengths.map(length =>
                `<option value="${length}"${length === state.params.l ? "selected" : ""}>${length}</option>`)
                .join()
            + `</select><label for="dt-length-0"> entries per page</label>`;
    }

    window.letterLinkClick = function (letter) {
        state.params = cleanObject({
            q: state.params.q,
            w: letter,
            e: undefined,
            l: state.params.l,
            d: undefined,
            c: true
        });
        history.pushState(state, '', urlFromState(state));
        table.api().ajax.reload();
    }

    function makeLetterLinks() {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
        const links = letters.map(letter =>
            `<a class="btn btn-outline-secondary lx-btn-letter" onclick="letterLinkClick('${letter}')">${letter}</a>`);
        return links.join('&nbsp;');
    }

    window.remakeDatatable = () => {
        if (table != null) {
            table.api().destroy();
            table = makeDatatable($('#words-search-result'), state.params.l, cachedTags);
            table.api().ajax.reload();
        }
    }

    function validateQueryField(queryField, button, query) {
        const q = query == null ? '' : query.trim();

        const valid = q === '' || validateQuery(q);
        queryField.toggleClass('input-validation-error', !valid);
        queryField.get(0).setCustomValidity(valid ? '' : 'Invalid query!');
        button.prop('disabled', !valid);

        return valid;
    }

    function updateFromQueryField(queryField) {
        const q = queryField.val().trim();
        state.params.q = q === '' ? undefined : q;
        pageEvent = 'query';
        table.api().ajax.reload();
    }

    function makeDatatable(selector, pageLength, tags) {
        return selector.dataTable({
            pagingType: 'full',
            lengthChange: false,
            pageLength: pageLength,
            scrollY: 'calc(100vh - 20.5rem)',
            scrollCollapse: true,
            serverSide: true,
            processing: true,
            columns: [
                { data: 'value', title: 'Word' }
            ].concat(tags.map(makeTagColumn)),
            altEditor: true,
            layout: {
                topStart: {
                    div: {
                        className: 'input-group',
                        html: makeFilterControl()
                    },
                    buttons: [
                        { name: 'tags', text: '<i class="fas fa-fw fa-tags fa-sm"></i>', action: showSelectTagsModal },
                        { name: 'refresh', text: '<i class="fas fa-fw fa-sync-alt fa-sm"></i>' }
                    ]
                },
                topEnd: {
                    div: {
                        className: 'dt-length',
                        html: makePageLengthControl()
                    }
                },
                bottomStart: {
                    div: {
                        html: makeLetterLinks()
                    }
                }
            },
            ajax: {
                url: `/api/words/${languageId}`,
                type: 'GET',
                cache: true,
                data: () => {
                    $('.dt-paging-button').addClass('disabled');

                    let push = false;

                    switch (pageEvent) {
                        case 'first':
                            push = true;
                            state.params.w = undefined;
                            state.params.e = undefined;
                            state.params.d = undefined;
                            break;
                        case 'previous':
                            push = true;
                            state.params.w = first;
                            state.params.e = first != null ? true : undefined;
                            state.params.d = true;
                            break;
                        case 'next':
                            push = true;
                            state.params.w = last;
                            state.params.e = last != null ? true : undefined;
                            state.params.d = undefined;
                            break;
                        case 'last':
                            push = true;
                            state.params.w = undefined;
                            state.params.e = undefined;
                            state.params.d = true;
                            break;
                        case 'columns':
                        case 'limit':
                        case 'query':
                            push = true;
                            break;
                    }

                    if (push) {
                        state.params = cleanObject(state.params);
                        history.pushState(state, '', urlFromState(state));
                    }
                    pageEvent = null;

                    return state.params;
                },
                dataFilter: response => {
                    const json = JSON.parse(response);

                    const words = json.words;
                    const prev = json.prev;
                    const next = json.next;

                    $('#card-words-text').text(`Words - ${json.count}`);
                    if (words.length > 0) {
                        first = words[0].value;
                        last = words[words.length - 1].value;
                    }

                    const count = (!prev && !next) ? pageLength : (pageLength * 5);
                    pageIndex = prev ? (next ? 2 : 4) : 0;

                    return JSON.stringify({
                        recordsTotal: count,
                        recordsFiltered: count,
                        data: convertWordResponse(words, tags)
                    });
                }
            },
            preDrawCallback: settings => {
                const api = new $.fn.dataTable.Api(settings);
                api.page(pageIndex);
                tags.forEach((tag, index) =>
                    api.column(index + 1).header().innerHTML = makeTagColumnHeader(tag));
            },
            initComplete: () => {
                $('#dt-length-0').on('change', e => {
                    state.params.l = parseInt(e.target.value);
                    pageEvent = 'limit';
                    table.api().ajax.reload();
                });

                const queryField = $('#words-search-filter');
                const button = $('#words-search-filter-button');
                queryField.on('change', e => {
                    validateQueryField(queryField, button, e.target.value);
                });
                queryField.on('search', e => {
                    if (validateQueryField(queryField, button, e.target.value))
                        updateFromQueryField(queryField);
                });
                button.on('click', () => updateFromQueryField(queryField));
            }
        });
    }

    $(window).on('popstate', e => {
        first = null;
        last = null;
        pageEvent = null;
        pageIndex = 0;

        const prevState = state;
        if (e.originalEvent.state != null) {
            state = e.originalEvent.state;
        } else {
            state.params = makeParams(model);
        }

        if (table != null) {
            if (!arraysEqual(prevState.tagColumns, state.tagColumns)) {
                const shrink = prevState.tagColumns.length > state.tagColumns.length;
                const selector = $('#words-search-result');
                const pageLength = state.params.l;
                const tagsReq = makeTagsReq(state.tagColumns);
                tagsReq.done(tags => {
                    table.api().destroy();
                    if (shrink) selector.empty();
                    table = makeDatatable(selector, pageLength, tags);
                    table.api().ajax.reload();
                });
            } else {
                $('#dt-length-0').val(state.params.l);
                $('#words-search-filter').val(state.params.q);
                table.api().ajax.reload();
            }
        }
    });

    const pageLength = fixPageLength(model.limit);
    const tagsReq = makeTagsReq(model.tagIds);
    $(document).ready(function () {
        initSelectTagsModal();
        document.addEventListener('click', e => {
            if ($(e.target).hasClass('page-link')) {
                pageEvent = e.target.getAttribute('data-dt-idx');
            }
        }, true);

        tagsReq.done(tags => table = makeDatatable($('#words-search-result'), pageLength, tags));
    });
}