// Generated from QueryParser.g4 by ANTLR 4.13.2
// jshint ignore: start
import antlr4 from 'antlr4';
import QueryParserListener from './QueryParserListener.js';
import QueryParserVisitor from './QueryParserVisitor.js';

const serializedATN = [4,1,14,73,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,
2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,1,0,1,0,1,0,1,1,1,1,1,
1,1,1,1,1,1,1,1,1,1,1,3,1,34,8,1,1,1,1,1,1,1,1,1,1,1,1,1,5,1,42,8,1,10,1,
12,1,45,9,1,1,2,1,2,1,2,1,2,3,2,51,8,2,1,3,1,3,1,3,1,4,1,4,1,5,1,5,1,5,1,
6,1,6,1,7,1,7,1,7,1,8,1,8,1,9,1,9,1,9,1,10,1,10,1,10,0,1,2,11,0,2,4,6,8,
10,12,14,16,18,20,0,0,68,0,22,1,0,0,0,2,33,1,0,0,0,4,50,1,0,0,0,6,52,1,0,
0,0,8,55,1,0,0,0,10,57,1,0,0,0,12,60,1,0,0,0,14,62,1,0,0,0,16,65,1,0,0,0,
18,67,1,0,0,0,20,70,1,0,0,0,22,23,3,2,1,0,23,24,5,0,0,1,24,1,1,0,0,0,25,
26,6,1,-1,0,26,27,5,3,0,0,27,34,3,2,1,5,28,29,5,4,0,0,29,30,3,2,1,0,30,31,
5,5,0,0,31,34,1,0,0,0,32,34,3,4,2,0,33,25,1,0,0,0,33,28,1,0,0,0,33,32,1,
0,0,0,34,43,1,0,0,0,35,36,10,4,0,0,36,37,5,1,0,0,37,42,3,2,1,5,38,39,10,
3,0,0,39,40,5,2,0,0,40,42,3,2,1,4,41,35,1,0,0,0,41,38,1,0,0,0,42,45,1,0,
0,0,43,41,1,0,0,0,43,44,1,0,0,0,44,3,1,0,0,0,45,43,1,0,0,0,46,51,3,6,3,0,
47,51,3,10,5,0,48,51,3,14,7,0,49,51,3,18,9,0,50,46,1,0,0,0,50,47,1,0,0,0,
50,48,1,0,0,0,50,49,1,0,0,0,51,5,1,0,0,0,52,53,5,7,0,0,53,54,3,8,4,0,54,
7,1,0,0,0,55,56,5,11,0,0,56,9,1,0,0,0,57,58,5,8,0,0,58,59,3,12,6,0,59,11,
1,0,0,0,60,61,5,12,0,0,61,13,1,0,0,0,62,63,5,9,0,0,63,64,3,16,8,0,64,15,
1,0,0,0,65,66,5,13,0,0,66,17,1,0,0,0,67,68,5,10,0,0,68,69,3,20,10,0,69,19,
1,0,0,0,70,71,5,14,0,0,71,21,1,0,0,0,4,33,41,43,50];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

const sharedContextCache = new antlr4.atn.PredictionContextCache();

export default class QueryParser extends antlr4.Parser {

    static grammarFileName = "QueryParser.g4";
    static literalNames = [ null, null, null, null, "'('", "')'", null, 
                            "'ti:'", "'tn:'", "'wp:'", "'wl:'" ];
    static symbolicNames = [ null, "AND", "OR", "NOT", "LPAREN", "RPAREN", 
                             "WHITESPACES", "TAG_ID_PREFIX", "TAG_NAME_PREFIX", 
                             "WORD_PATTERN_PREFIX", "WORD_LENGTH_PREFIX", 
                             "TAG_ID", "TAG_NAME", "WORD_PATTERN", "WORD_LENGTH_RANGE" ];
    static ruleNames = [ "query", "expression", "term", "tagIdTerm", "tagIdValue", 
                         "tagNameTerm", "tagNameValue", "wordPatternTerm", 
                         "wordPatternValue", "wordLengthTerm", "wordLengthRange" ];

    constructor(input) {
        super(input);
        this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
        this.ruleNames = QueryParser.ruleNames;
        this.literalNames = QueryParser.literalNames;
        this.symbolicNames = QueryParser.symbolicNames;
    }

    sempred(localctx, ruleIndex, predIndex) {
    	switch(ruleIndex) {
    	case 1:
    	    		return this.expression_sempred(localctx, predIndex);
        default:
            throw "No predicate with index:" + ruleIndex;
       }
    }

    expression_sempred(localctx, predIndex) {
    	switch(predIndex) {
    		case 0:
    			return this.precpred(this._ctx, 4);
    		case 1:
    			return this.precpred(this._ctx, 3);
    		default:
    			throw "No predicate with index:" + predIndex;
    	}
    };




	query() {
	    let localctx = new QueryContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 0, QueryParser.RULE_query);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 22;
	        this.expression(0);
	        this.state = 23;
	        this.match(QueryParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


	expression(_p) {
		if(_p===undefined) {
		    _p = 0;
		}
	    const _parentctx = this._ctx;
	    const _parentState = this.state;
	    let localctx = new ExpressionContext(this, this._ctx, _parentState);
	    let _prevctx = localctx;
	    const _startState = 2;
	    this.enterRecursionRule(localctx, 2, QueryParser.RULE_expression, _p);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 33;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 3:
	            localctx = new NotExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;

	            this.state = 26;
	            this.match(QueryParser.NOT);
	            this.state = 27;
	            this.expression(5);
	            break;
	        case 4:
	            localctx = new BracketExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 28;
	            this.match(QueryParser.LPAREN);
	            this.state = 29;
	            this.expression(0);
	            this.state = 30;
	            this.match(QueryParser.RPAREN);
	            break;
	        case 7:
	        case 8:
	        case 9:
	        case 10:
	            localctx = new TermExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 32;
	            this.term();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	        this._ctx.stop = this._input.LT(-1);
	        this.state = 43;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,2,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                if(this._parseListeners!==null) {
	                    this.triggerExitRuleEvent();
	                }
	                _prevctx = localctx;
	                this.state = 41;
	                this._errHandler.sync(this);
	                var la_ = this._interp.adaptivePredict(this._input,1,this._ctx);
	                switch(la_) {
	                case 1:
	                    localctx = new AndExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, QueryParser.RULE_expression);
	                    this.state = 35;
	                    if (!( this.precpred(this._ctx, 4))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 4)");
	                    }
	                    this.state = 36;
	                    this.match(QueryParser.AND);
	                    this.state = 37;
	                    this.expression(5);
	                    break;

	                case 2:
	                    localctx = new OrExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, QueryParser.RULE_expression);
	                    this.state = 38;
	                    if (!( this.precpred(this._ctx, 3))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 3)");
	                    }
	                    this.state = 39;
	                    this.match(QueryParser.OR);
	                    this.state = 40;
	                    this.expression(4);
	                    break;

	                } 
	            }
	            this.state = 45;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,2,this._ctx);
	        }

	    } catch( error) {
	        if(error instanceof antlr4.error.RecognitionException) {
		        localctx.exception = error;
		        this._errHandler.reportError(this, error);
		        this._errHandler.recover(this, error);
		    } else {
		    	throw error;
		    }
	    } finally {
	        this.unrollRecursionContexts(_parentctx)
	    }
	    return localctx;
	}



	term() {
	    let localctx = new TermContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 4, QueryParser.RULE_term);
	    try {
	        this.state = 50;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 7:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 46;
	            this.tagIdTerm();
	            break;
	        case 8:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 47;
	            this.tagNameTerm();
	            break;
	        case 9:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 48;
	            this.wordPatternTerm();
	            break;
	        case 10:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 49;
	            this.wordLengthTerm();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	tagIdTerm() {
	    let localctx = new TagIdTermContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 6, QueryParser.RULE_tagIdTerm);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 52;
	        this.match(QueryParser.TAG_ID_PREFIX);
	        this.state = 53;
	        this.tagIdValue();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	tagIdValue() {
	    let localctx = new TagIdValueContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 8, QueryParser.RULE_tagIdValue);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 55;
	        this.match(QueryParser.TAG_ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	tagNameTerm() {
	    let localctx = new TagNameTermContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 10, QueryParser.RULE_tagNameTerm);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 57;
	        this.match(QueryParser.TAG_NAME_PREFIX);
	        this.state = 58;
	        this.tagNameValue();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	tagNameValue() {
	    let localctx = new TagNameValueContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 12, QueryParser.RULE_tagNameValue);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 60;
	        this.match(QueryParser.TAG_NAME);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	wordPatternTerm() {
	    let localctx = new WordPatternTermContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 14, QueryParser.RULE_wordPatternTerm);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 62;
	        this.match(QueryParser.WORD_PATTERN_PREFIX);
	        this.state = 63;
	        this.wordPatternValue();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	wordPatternValue() {
	    let localctx = new WordPatternValueContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 16, QueryParser.RULE_wordPatternValue);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 65;
	        this.match(QueryParser.WORD_PATTERN);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	wordLengthTerm() {
	    let localctx = new WordLengthTermContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 18, QueryParser.RULE_wordLengthTerm);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 67;
	        this.match(QueryParser.WORD_LENGTH_PREFIX);
	        this.state = 68;
	        this.wordLengthRange();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	wordLengthRange() {
	    let localctx = new WordLengthRangeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 20, QueryParser.RULE_wordLengthRange);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 70;
	        this.match(QueryParser.WORD_LENGTH_RANGE);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


}

QueryParser.EOF = antlr4.Token.EOF;
QueryParser.AND = 1;
QueryParser.OR = 2;
QueryParser.NOT = 3;
QueryParser.LPAREN = 4;
QueryParser.RPAREN = 5;
QueryParser.WHITESPACES = 6;
QueryParser.TAG_ID_PREFIX = 7;
QueryParser.TAG_NAME_PREFIX = 8;
QueryParser.WORD_PATTERN_PREFIX = 9;
QueryParser.WORD_LENGTH_PREFIX = 10;
QueryParser.TAG_ID = 11;
QueryParser.TAG_NAME = 12;
QueryParser.WORD_PATTERN = 13;
QueryParser.WORD_LENGTH_RANGE = 14;

QueryParser.RULE_query = 0;
QueryParser.RULE_expression = 1;
QueryParser.RULE_term = 2;
QueryParser.RULE_tagIdTerm = 3;
QueryParser.RULE_tagIdValue = 4;
QueryParser.RULE_tagNameTerm = 5;
QueryParser.RULE_tagNameValue = 6;
QueryParser.RULE_wordPatternTerm = 7;
QueryParser.RULE_wordPatternValue = 8;
QueryParser.RULE_wordLengthTerm = 9;
QueryParser.RULE_wordLengthRange = 10;

class QueryContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryParser.RULE_query;
    }

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	EOF() {
	    return this.getToken(QueryParser.EOF, 0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.enterQuery(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.exitQuery(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryParserVisitor ) {
	        return visitor.visitQuery(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ExpressionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryParser.RULE_expression;
    }


	 
		copyFrom(ctx) {
			super.copyFrom(ctx);
		}

}


class BracketExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	LPAREN() {
	    return this.getToken(QueryParser.LPAREN, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	RPAREN() {
	    return this.getToken(QueryParser.RPAREN, 0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.enterBracketExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.exitBracketExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryParserVisitor ) {
	        return visitor.visitBracketExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

QueryParser.BracketExpressionContext = BracketExpressionContext;

class AndExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	AND() {
	    return this.getToken(QueryParser.AND, 0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.enterAndExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.exitAndExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryParserVisitor ) {
	        return visitor.visitAndExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

QueryParser.AndExpressionContext = AndExpressionContext;

class TermExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	term() {
	    return this.getTypedRuleContext(TermContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.enterTermExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.exitTermExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryParserVisitor ) {
	        return visitor.visitTermExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

QueryParser.TermExpressionContext = TermExpressionContext;

class NotExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	NOT() {
	    return this.getToken(QueryParser.NOT, 0);
	};

	expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.enterNotExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.exitNotExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryParserVisitor ) {
	        return visitor.visitNotExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

QueryParser.NotExpressionContext = NotExpressionContext;

class OrExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	OR() {
	    return this.getToken(QueryParser.OR, 0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.enterOrExpression(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.exitOrExpression(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryParserVisitor ) {
	        return visitor.visitOrExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

QueryParser.OrExpressionContext = OrExpressionContext;

class TermContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryParser.RULE_term;
    }

	tagIdTerm() {
	    return this.getTypedRuleContext(TagIdTermContext,0);
	};

	tagNameTerm() {
	    return this.getTypedRuleContext(TagNameTermContext,0);
	};

	wordPatternTerm() {
	    return this.getTypedRuleContext(WordPatternTermContext,0);
	};

	wordLengthTerm() {
	    return this.getTypedRuleContext(WordLengthTermContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.enterTerm(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.exitTerm(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryParserVisitor ) {
	        return visitor.visitTerm(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TagIdTermContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryParser.RULE_tagIdTerm;
    }

	TAG_ID_PREFIX() {
	    return this.getToken(QueryParser.TAG_ID_PREFIX, 0);
	};

	tagIdValue() {
	    return this.getTypedRuleContext(TagIdValueContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.enterTagIdTerm(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.exitTagIdTerm(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryParserVisitor ) {
	        return visitor.visitTagIdTerm(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TagIdValueContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryParser.RULE_tagIdValue;
    }

	TAG_ID() {
	    return this.getToken(QueryParser.TAG_ID, 0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.enterTagIdValue(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.exitTagIdValue(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryParserVisitor ) {
	        return visitor.visitTagIdValue(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TagNameTermContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryParser.RULE_tagNameTerm;
    }

	TAG_NAME_PREFIX() {
	    return this.getToken(QueryParser.TAG_NAME_PREFIX, 0);
	};

	tagNameValue() {
	    return this.getTypedRuleContext(TagNameValueContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.enterTagNameTerm(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.exitTagNameTerm(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryParserVisitor ) {
	        return visitor.visitTagNameTerm(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TagNameValueContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryParser.RULE_tagNameValue;
    }

	TAG_NAME() {
	    return this.getToken(QueryParser.TAG_NAME, 0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.enterTagNameValue(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.exitTagNameValue(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryParserVisitor ) {
	        return visitor.visitTagNameValue(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class WordPatternTermContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryParser.RULE_wordPatternTerm;
    }

	WORD_PATTERN_PREFIX() {
	    return this.getToken(QueryParser.WORD_PATTERN_PREFIX, 0);
	};

	wordPatternValue() {
	    return this.getTypedRuleContext(WordPatternValueContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.enterWordPatternTerm(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.exitWordPatternTerm(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryParserVisitor ) {
	        return visitor.visitWordPatternTerm(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class WordPatternValueContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryParser.RULE_wordPatternValue;
    }

	WORD_PATTERN() {
	    return this.getToken(QueryParser.WORD_PATTERN, 0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.enterWordPatternValue(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.exitWordPatternValue(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryParserVisitor ) {
	        return visitor.visitWordPatternValue(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class WordLengthTermContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryParser.RULE_wordLengthTerm;
    }

	WORD_LENGTH_PREFIX() {
	    return this.getToken(QueryParser.WORD_LENGTH_PREFIX, 0);
	};

	wordLengthRange() {
	    return this.getTypedRuleContext(WordLengthRangeContext,0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.enterWordLengthTerm(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.exitWordLengthTerm(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryParserVisitor ) {
	        return visitor.visitWordLengthTerm(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class WordLengthRangeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = QueryParser.RULE_wordLengthRange;
    }

	WORD_LENGTH_RANGE() {
	    return this.getToken(QueryParser.WORD_LENGTH_RANGE, 0);
	};

	enterRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.enterWordLengthRange(this);
		}
	}

	exitRule(listener) {
	    if(listener instanceof QueryParserListener ) {
	        listener.exitWordLengthRange(this);
		}
	}

	accept(visitor) {
	    if ( visitor instanceof QueryParserVisitor ) {
	        return visitor.visitWordLengthRange(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}




QueryParser.QueryContext = QueryContext; 
QueryParser.ExpressionContext = ExpressionContext; 
QueryParser.TermContext = TermContext; 
QueryParser.TagIdTermContext = TagIdTermContext; 
QueryParser.TagIdValueContext = TagIdValueContext; 
QueryParser.TagNameTermContext = TagNameTermContext; 
QueryParser.TagNameValueContext = TagNameValueContext; 
QueryParser.WordPatternTermContext = WordPatternTermContext; 
QueryParser.WordPatternValueContext = WordPatternValueContext; 
QueryParser.WordLengthTermContext = WordLengthTermContext; 
QueryParser.WordLengthRangeContext = WordLengthRangeContext; 
