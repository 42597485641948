// Generated from QueryParser.g4 by ANTLR 4.13.2
// jshint ignore: start
import antlr4 from 'antlr4';

// This class defines a complete generic visitor for a parse tree produced by QueryParser.

export default class QueryParserVisitor extends antlr4.tree.ParseTreeVisitor {

	// Visit a parse tree produced by QueryParser#query.
	visitQuery(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryParser#BracketExpression.
	visitBracketExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryParser#AndExpression.
	visitAndExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryParser#TermExpression.
	visitTermExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryParser#NotExpression.
	visitNotExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryParser#OrExpression.
	visitOrExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryParser#term.
	visitTerm(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryParser#tagIdTerm.
	visitTagIdTerm(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryParser#tagIdValue.
	visitTagIdValue(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryParser#tagNameTerm.
	visitTagNameTerm(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryParser#tagNameValue.
	visitTagNameValue(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryParser#wordPatternTerm.
	visitWordPatternTerm(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryParser#wordPatternValue.
	visitWordPatternValue(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryParser#wordLengthTerm.
	visitWordLengthTerm(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by QueryParser#wordLengthRange.
	visitWordLengthRange(ctx) {
	  return this.visitChildren(ctx);
	}



}