import 'bootstrap';
import fontawesome from '@fortawesome/fontawesome-free';
import DataTable from 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-select-bs4';
import { setupEditionEnabled } from "./setupEdition";
import { setupWordList } from "./wordList";
import { setupTagList } from "./tagList";
import '../scss/site.scss';

fontawesome.config = { autoReplaceSvg: false };

Object.assign(DataTable.defaults, {
    info: false,
    ordering: false,
    paging: true,
    searching: true
});
DataTable.Buttons.defaults.dom.container.className = 'dt-buttons btn-group';
DataTable.Buttons.defaults.dom.button.className = 'btn btn-secondary btn-sm';

window.setupEditionEnabled = setupEditionEnabled;
window.setupWordList = setupWordList;
window.setupTagList = setupTagList;

console.log('The \'site\' bundle has been loaded!');